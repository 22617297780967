import {
	Alert,
	Badge,
	Box,
	Button,
	Chip,
	LinearProgress,
	Modal,
	Stack,
	TextField,
} from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useMemo, useState } from 'react';
import { useGeolocated } from 'react-geolocated';
import { useDispatch } from 'react-redux';
import {
	IDeliveryActionsRequest,
	IDriverPackages,
	IPackage,
} from '../../../DTOs';
import ConfirmationButton from '../../../components/Confirmation';
import { useAuth } from '../../../hooks/auth';
import api from '../../../services/api';
import { queryClient } from '../../../services/queryClient';
import { addHistoryItem } from '../../../store/historySlice';
import { enqueuePendingRequest } from '../../../store/requestQueueSlice';
import { sendRequest } from '../../../utils/driverActions';
import { handleSearchPackage } from '../../../utils/filtering';
import PackageCard from './PackageCard';
import PackageDisplayButton from './PackageList';

const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '90%',
	maxWidth: '400px',
	maxHeight: '90%',
	overflowY: 'auto',
	bgcolor: 'white',
	border: '2px solid #000',
	boxShadow: 24,
};

const url = '/operations/collected';

interface IOrderList {
	destination: string;
}

const OrderList = ({ destination }: IOrderList) => {
	const [search, setSearch] = useState('');
	const [open, setOpen] = useState(false);

	const [selected, setSelected] = useState<string[]>([]);

	const { user } = useAuth();
	const { enqueueSnackbar } = useSnackbar();
	const dispatch = useDispatch();

	const { coords, isGeolocationAvailable, isGeolocationEnabled } =
		useGeolocated({
			positionOptions: {
				enableHighAccuracy: false,
			},
			userDecisionTimeout: 5000,
		});

	const { isLoading, data } = useQuery(
		[`/drivers/${user.id}/collect`],
		async () => {
			const { data } = await api.get<IDriverPackages>(
				`/drivers/${user.id}/collect`
			);

			return data;
		},
		{
			onError: () => {
				enqueueSnackbar('Não foi possível receber os pedidos de coleta', {
					variant: 'error',
				});
			},
			networkMode: 'offlineFirst',
		}
	);

	const collecting = useMemo(() => {
		try {
			if (!data) return [] as IPackage[];

			if (data && !data.packages) return [] as IPackage[];

			return data.packages.filter((item) => handleSearchPackage(item, search));
		} catch (error) {
			return [] as IPackage[];
		}
	}, [data, search]);

	const collectMutation = useMutation(
		async () => {
			if (!isGeolocationAvailable) {
				enqueueSnackbar('Localização não disponível', {
					variant: 'warning',
				});
			}

			if (!isGeolocationEnabled) {
				enqueueSnackbar('Localização bloqueada', {
					variant: 'warning',
				});
			}

			let coordinates = null as string | null;

			if (coords) {
				coordinates = `(${coords.latitude},${coords.longitude})`;
			}

			const packages = selected.map((code) => ({
				term: code,
				data: {
					coordinates,
				},
			}));

			const body: IDeliveryActionsRequest = {
				packages,
			};

			if (!navigator.onLine) {
				dispatch(
					enqueuePendingRequest({
						body,
						userId: user.id,
						url,
					})
				);
			} else {
				try {
					await sendRequest({
						packages: body,
						destination,
						url,
					});

					dispatch(
						addHistoryItem({
							type: 'collect',
							data: body,
						})
					);
				} catch (error) {
					enqueueSnackbar(`Erro ao realizar coleta, tente novamente!`, {
						variant: 'error',
					});
				}
			}
		},
		{
			onSettled: () => {
				setSelected([]);
			},
			onSuccess: async () => {
				const collectQuery = `/drivers/${user.id}/collect`;

				if (selected) {
					queryClient.setQueryData(
						[collectQuery],
						(oldPackages: IDriverPackages | undefined) =>
							oldPackages &&
							oldPackages.packages && {
								driverId: oldPackages.driverId,
								packages: oldPackages.packages.filter((p) =>
									selected.includes(p.alphaCode)
								) as IPackage[],
							}
					);
				}

				await queryClient.invalidateQueries([collectQuery]);

				enqueueSnackbar(`Coleta realizada com sucesso!`, {
					variant: 'success',
				});
			},
			onError: () => {
				enqueueSnackbar(
					`Houve um erro ao registrar a atualização do pacote, tente novamente`,
					{
						variant: 'error',
					}
				);
			},
		}
	);

	function handleSelectPackage(code: string) {
		const exists = selected.find((pkg) => pkg === code);

		if (!exists) {
			setSelected([...selected, code]);
		} else {
			setSelected(selected.filter((pkg) => pkg !== code));
		}
	}

	if (isLoading) {
		return (
			<Box
				sx={{
					display: 'flex',
					width: '100%',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<LinearProgress
					sx={{
						width: '100%',
					}}
				/>
			</Box>
		);
	}

	return (
		<Stack
			sx={{
				display: 'flex',
				width: '100%',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			<Modal open={open} onClose={() => setOpen(false)}>
				<Stack
					width="100%"
					direction="column"
					justifyContent="flex-start"
					alignItems="center"
					spacing={2}
					sx={style}
				>
					<Stack
						position="sticky"
						top="0px"
						width="100%"
						direction="column"
						justifyContent="flex-start"
						alignItems="center"
						spacing={2}
						bgcolor="white"
						zIndex={2}
						padding={2}
					>
						<TextField
							fullWidth
							label="Buscar pacote"
							value={search}
							onChange={(event) => setSearch(event.target.value)}
						/>

						<Stack
							width="100%"
							direction="row"
							justifyContent="space-between"
							alignItems="center"
							spacing={2}
						>
							<ConfirmationButton
								trigger={
									<Button fullWidth variant="outlined">
										Limpar
									</Button>
								}
								action={() => setSelected([])}
								text="Realmente deseja limpar a seleção de pedidos?"
							/>

							<Button
								fullWidth
								variant="contained"
								onClick={() => collectMutation.mutate()}
							>
								Coletar
							</Button>
						</Stack>

						<Stack
							width="100%"
							direction="row"
							justifyContent="space-between"
							alignItems="center"
							spacing={2}
						>
							<Badge
								color="primary"
								badgeContent={selected.length}
								anchorOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
							>
								<Chip label="Selecionados" />
							</Badge>

							<Badge
								color="primary"
								badgeContent={collecting.length}
								anchorOrigin={{
									vertical: 'top',
									horizontal: 'left',
								}}
							>
								<Chip label="Disponíveis" />
							</Badge>
						</Stack>
					</Stack>

					<Stack
						width="100%"
						direction="column"
						justifyContent="flex-start"
						alignItems="center"
						spacing={2}
						padding={2}
					>
						{collecting.length === 0 ? (
							<Alert severity="info">
								Nenhum pacote encontrado para esta busca
							</Alert>
						) : (
							collecting.map((c) => (
								<PackageCard
									package={c}
									key={c.code}
									toggleSelection={handleSelectPackage}
									selected={selected.includes(c.alphaCode)}
								/>
							))
						)}
					</Stack>
				</Stack>
			</Modal>

			<PackageDisplayButton
				collecting={collecting}
				handleOpen={() => setOpen(!open)}
			/>
		</Stack>
	);
};

export default OrderList;
