import {
	createContext,
	ReactNode,
	useCallback,
	useContext,
	useMemo,
	useState,
} from 'react';
import DeliveryModalities from '../components/DeliveryModalities';
import { IModality } from '../DTOs';
import api from '../services/api';
import { local, session } from '../utils/storage';

interface IUser {
	id: string;
	name: string;
	cpf: string | null;
	email: string | null;
	roles: string[];
	[x: string]: unknown;
}

interface AuhtState {
	token: string;
	user: IUser;
}

interface SignInCredentials {
	username: string;
	password: string;
}

interface AuthContextData {
	user: IUser;
	signIn(credentials: SignInCredentials): Promise<void>;
	signOut: () => void;
	updateUser: (user: IUser) => void;
	canCollect: boolean;
	isEmployee: boolean;
	handleUpdateModality: (modality: string | null) => void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

interface IAuthProps {
	children: ReactNode;
}

const AuthProvider = ({ children }: IAuthProps) => {
	const [data, setData] = useState<AuhtState>(() => {
		const { user, token } = JSON.parse(
			session.getEncrypted('@vaifacilbr:userdata') || '{}'
		);

		if (token && user) {
			api.defaults.headers.common.authorization = `Bearer ${token}`;
			return { token, user };
		}

		return {} as AuhtState;
	});
	const [modalities, setModalities] = useState<IModality[]>([]);

	const signOut = useCallback(() => {
		local.clear();
		session.clear();
		setData({} as AuhtState);
	}, []);

	const signIn = useCallback(
		async ({ username, password }: SignInCredentials) => {
			const loginData = {
				username,
				password,
			};

			const response = await api.post<AuhtState>('/auth/login', loginData);

			const { token, user } = response.data;

			setData({ token, user });

			session.setEncrypted(
				'@vaifacilbr:userdata',
				JSON.stringify({ token, user })
			);

			api.defaults.headers.common.authorization = `Bearer ${token}`;

			const modalitiesReponse = await api.get<IModality[]>(
				'/operations/delivery/modalities'
			);

			setModalities(modalitiesReponse.data);

			local.setEncrypted(
				'@vaifacilbr:modal-cache',
				JSON.stringify(response.data)
			);
		},
		[]
	);

	const updateUser = useCallback(
		(user: IUser) => {
			const newUserData = { user, token: data.token };

			session.setEncrypted('@vaifacilbr:userdata', JSON.stringify(newUserData));

			setData(newUserData);
		},
		[data.token]
	);

	const canCollect = useMemo(() => {
		if (!data.user) return false;

		if (data.user.roles.includes('8')) return true;

		if (data.user.roles.includes('1')) return true;

		return false;
	}, [data]);

	const isEmployee = useMemo(() => {
		if (!data.user) return false;

		if (data.user.isEmployee || data.user.isSystemAdmin) {
			return true;
		}
		return false;
	}, [data]);

	const showModalitySelection = useMemo(() => {
		if (!data) return false;

		if (!data.user) return false;

		return !data.user.deliveryModality;
	}, [data]);

	const handleUpdateModality = useCallback(
		(modality: string | null) => {
			const newUser = { ...data.user };

			Object.assign(newUser, { deliveryModality: modality });

			updateUser(newUser);
		},
		[data.user, updateUser]
	);

	const contextValue = useMemo(
		() => ({
			user: data.user,
			signIn,
			signOut,
			updateUser,
			canCollect,
			isEmployee,
			handleUpdateModality,
		}),
		[
			canCollect,
			data.user,
			handleUpdateModality,
			isEmployee,
			signIn,
			signOut,
			updateUser,
		]
	);

	return (
		<AuthContext.Provider value={contextValue}>
			{showModalitySelection ? (
				<DeliveryModalities
					modalities={modalities}
					handleSetModality={handleUpdateModality}
					signOut={signOut}
				/>
			) : (
				children
			)}
		</AuthContext.Provider>
	);
};

function useAuth() {
	const context = useContext(AuthContext);

	return context;
}

export { AuthProvider, useAuth };
