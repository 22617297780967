import { EmojiFlags } from '@mui/icons-material';
import {
	Alert,
	AlertTitle,
	Box,
	CircularProgress,
	Stack,
	Typography,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useMemo } from 'react';
import { useGeolocated } from 'react-geolocated';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import {
	IDeliveryActions,
	IDeliveryActionsRequest,
	IDeliveryData,
	IDriverPackages,
	IPackage,
} from '../../../../DTOs';
import ReverseBadge from '../../../../components/ReverseBadge';
import { useAuth } from '../../../../hooks/auth';
import { queryClient } from '../../../../services/queryClient';
import { addHistoryItem } from '../../../../store/historySlice';
import { enqueuePendingRequest } from '../../../../store/requestQueueSlice';
import {
	deliverySendRequest,
	sendRequest,
} from '../../../../utils/driverActions';
import Action from './ActionAccordion';
import Deliver from './Actions/Deliver';
import Occurrence from './Actions/Occurrence';
import InfoItem from './InfoItem';

interface IDeliverActionsProps {
	data: IPackage;
	handleClose: () => void;
}

interface IOccurrenceResponse {
	occurrence: string;
	description: string;
}

const DeliverActions = ({ data, handleClose }: IDeliverActionsProps) => {
	const { enqueueSnackbar } = useSnackbar();
	const dispatch = useDispatch();
	const { user } = useAuth();

	const methods = useForm<IDeliveryActions>({
		defaultValues: {
			receiver: {
				document: '',
				name: '',
				attachments: [],
			},
		},
	});

	const order = useMemo(() => {
		const {
			alphaCode,
			location,
			complement,
			recipient,
			phone,
			occurrences,
			tries,
			zipcode,
			coordinates,
			isReverse,
		} = data;

		const scheduled = new Date(data.deliverySla).toLocaleString('pt-BR');

		const haveCoordinates = !!coordinates;

		const wazeLink = haveCoordinates
			? `https://www.waze.com/ul?ll=${coordinates.x}%2C${coordinates.y}&navigate=yes&zoom=17`
			: `https://waze.com/ul?q=${location}&navigate=yes&zoom=17`;

		return {
			isReverse,
			id: alphaCode,
			scheduled,
			zipcode,
			address: location,
			complement,
			recipient,
			phone,
			occurrences: occurrences.map((o) => ({ ...o, commentRequired: false })),
			wazeLink,
			tries: Number(tries || 0) + 1,
		};
	}, [data]);

	const { coords, isGeolocationAvailable, isGeolocationEnabled } =
		useGeolocated({
			positionOptions: {
				enableHighAccuracy: false,
			},
			userDecisionTimeout: 5000,
		});

	const { mutate: handleDeliver, isLoading } = useMutation(
		async (deliverAction: IDeliveryActions) => {
			if (!isGeolocationAvailable) {
				enqueueSnackbar('Localização não disponível', {
					variant: 'warning',
				});
			}

			if (!isGeolocationEnabled) {
				enqueueSnackbar('Localização bloqueada', {
					variant: 'warning',
				});
			}

			let coordinates = null;

			if (coords) {
				coordinates = `(${coords.latitude},${coords.longitude})`;
			}

			const { order, occurrence, receiver } = deliverAction;

			if (deliverAction.type === 'deliver' && receiver) {
				const packages = [
					{
						term: order.alphaCode,
						data: {
							receiver,
							coordinates,
						},
					},
				];

				const body: IDeliveryActionsRequest = {
					packages,
				};

				if (!navigator.onLine) {
					dispatch(
						enqueuePendingRequest({
							body,
							url: '/operations/delivered',
							userId: user.id,
						})
					);
				} else {
					try {
						await deliverySendRequest({
							deliveryAction: body,
						});

						dispatch(
							addHistoryItem({
								type: 'delivery',
								data: body,
							})
						);
					} catch (error) {
						enqueueSnackbar(`Erro ao realizar entrega, tente novamente!`, {
							variant: 'error',
						});
					}
				}
			}

			if (deliverAction.type === 'occurrence' && occurrence) {
				const url = '/operations/occurrence';

				const pkg = [
					{
						package: {
							term: order.alphaCode,
							data: {
								occurrence: occurrence.id,
								coordinates,
							},
						},
					},
				];

				const packages = [
					{
						term: order.alphaCode,
						data: {
							occurrence: occurrence.id,
							coordinates,
						},
					},
				];

				const body: IDeliveryActionsRequest = {
					packages,
				};

				if (occurrence.description) {
					Object.assign(pkg[0].package.data, {
						description: occurrence.description,
					});
				}

				if (!navigator.onLine) {
					dispatch(
						enqueuePendingRequest({
							body,
							url,
							userId: user.id,
						})
					);
				} else {
					try {
						await sendRequest({ url, packages: body });

						dispatch(
							addHistoryItem({
								type: 'ocurrence',
								data: body,
							})
						);
					} catch (error) {
						enqueueSnackbar(`Erro ao registrar ocorrência, tente novamente!`, {
							variant: 'error',
						});
					}
				}
			}
		},
		{
			onSuccess: async () => {
				const deliveryQuery = `/drivers/${user.id}/deliveries`;

				if (data) {
					queryClient.setQueryData(
						[deliveryQuery],
						(oldDeliveries: IDriverPackages | undefined) =>
							oldDeliveries && {
								driverId: oldDeliveries.driverId,
								packages: oldDeliveries.packages.filter(
									(p) => p.alphaCode !== data.alphaCode
								) as IPackage[],
							}
					);
				}

				enqueueSnackbar(`Pacote atualizado com sucesso!`, {
					variant: 'success',
				});

				handleClose();
			},
			onError: () => {
				enqueueSnackbar(
					`Houve um erro ao registrar a atualização do pacote, tente novamente`,
					{
						variant: 'error',
					}
				);
			},
			networkMode: 'offlineFirst',
		}
	);

	function handleOccurence({ occurrence, description }: IOccurrenceResponse) {
		const occurrenceObj = {
			type: 'occurrence',
			order: data,
			occurrence: {
				id: occurrence,
				description,
			},
		} as IDeliveryActions;

		handleDeliver(occurrenceObj);
	}

	function handleDelivery({
		name,
		document,
		attachments,
		signature,
	}: IDeliveryData) {
		const deliverObj = {
			type: 'deliver',
			receiver: {
				name,
				document,
				attachments,
				signature,
			},
			order: data,
		} as IDeliveryActions;

		handleDeliver(deliverObj);
	}

	const occurrenceFiltered = useMemo(() => {
		if (!data.isReverse) {
			const occurrences = data.occurrences.filter(
				(ocurrence) => ocurrence.id !== '247'
			);

			return occurrences;
		}
		return data.occurrences;
	}, [data.isReverse, data.occurrences]);

	if (isLoading) {
		return (
			<Stack
				component="div"
				width="90%"
				maxWidth="400px"
				maxHeight="90%"
				display="flex"
				flexDirection="column"
				justifyContent="flex-start"
				alignItems="center"
				spacing={2}
				sx={{
					background: 'white',
					borderRadius: '8px',
					padding: 4,
					overflowY: 'auto',
				}}
			>
				<CircularProgress size="4rem" />
			</Stack>
		);
	}

	return (
		<FormProvider {...methods}>
			<Stack
				component="div"
				width="90%"
				maxWidth="400px"
				maxHeight="90%"
				display="flex"
				flexDirection="column"
				justifyContent="flex-start"
				alignItems="center"
				spacing={2}
				sx={{
					background: 'white',
					borderRadius: '8px',
					padding: 4,
					overflowY: 'auto',
				}}
			>
				<Stack
					width="100%"
					height="0px"
					sx={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						Tentativa nº{order.tries}
						<EmojiFlags />
					</Box>
				</Stack>

				<Box width="100%" textAlign="left">
					<Typography fontWeight="600" fontSize="1.2rem">
						Pacote
					</Typography>
					<ReverseBadge isReverse={order.isReverse}>
						<Typography fontSize="1rem" fontWeight="300">
							{order.id}
						</Typography>
					</ReverseBadge>
				</Box>
				<InfoItem
					title="Endereço"
					info={order.address}
					additionalData={
						<section>
							<a
								href={`https://www.google.com/maps/place/${order.address}`}
								target="_new"
							>
								<Typography style={{ fontWeight: 'bolder' }}>
									Ver no mapa
								</Typography>
							</a>
							<a href={order.wazeLink} target="_new">
								<Typography style={{ fontWeight: 'bolder' }}>
									Abrir no Waze
								</Typography>
							</a>
						</section>
					}
				/>

				<InfoItem title="CEP" info={order.zipcode} />

				{order.complement && (
					<InfoItem title="Complemento" info={order.complement} />
				)}

				<InfoItem title="Destinatário" info={order.recipient} />

				{order.phone && <InfoItem title="Telefone" info={order.phone} />}

				{order.isReverse ? (
					<Alert severity="info">
						<AlertTitle> Coleta Reversa </AlertTitle>O próximo passo deste
						pedido é ser armazenado por um conferente da Vai Fácil
					</Alert>
				) : (
					<>
						<Action
							title="Comprovar Entrega"
							component={
								<Deliver handleDeliver={handleDelivery} code={data.alphaCode} />
							}
						/>

						<Action
							title="Reportar Ocorrência"
							component={
								<Occurrence
									occurrences={occurrenceFiltered}
									handleOccurrence={handleOccurence}
								/>
							}
						/>
					</>
				)}
			</Stack>
		</FormProvider>
	);
};

export default DeliverActions;
