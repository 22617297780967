import { Alert, Box, CircularProgress, Stack, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useMemo, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { ISummaryData, IWalletResponse } from '../../DTOs';
import Layout from '../../components/Layout';
import { useAuth } from '../../hooks/auth';
import api from '../../services/api';
import SummaryCard from './SummaryCard';

const Wallet = () => {
	const [date, setDate] = useState<string>(new Date().toISOString());

	const { user } = useAuth();

	const { enqueueSnackbar } = useSnackbar();

	const cycle = useMemo(() => `${String(date).slice(0, 7)}-01`, [date]);

	const { isLoading, data } = useQuery(
		[`/drivers/${user.id}/fees/${cycle}`],
		async () => {
			const { data } = await api.get<IWalletResponse>(
				`/drivers/${user.id}/fees/${cycle}`
			);

			return data;
		},
		{
			onError: () => {
				enqueueSnackbar('Não foi possível consultar estes dados', {
					variant: 'error',
				});
			},
			networkMode: 'offlineFirst',
		}
	);

	const summaryByPeriod = useMemo(() => {
		if (!data) return [];

		if (data && !data.deliveries) return [];

		return data.deliveries.reduce((acc, curr) => {
			const existentPeriod = acc.find(
				(p) =>
					p.period.from === curr.periodFrom && p.period.to === curr.periodTo
			);

			if (!existentPeriod) {
				acc.push({
					period: {
						from: curr.periodFrom,
						to: curr.periodTo,
						payment: curr.paymentDate,
					},
					deliveries: [curr],
				});
			} else {
				const index = acc.indexOf(existentPeriod);

				existentPeriod.deliveries.push(curr);

				acc.splice(index, 1, existentPeriod);
			}

			return acc;
		}, [] as ISummaryData[]);
	}, [data]);

	if (isLoading) {
		return (
			<Layout>
				<Box
					sx={{
						display: 'flex',
						width: '100%',
						height: '100%',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<CircularProgress size="4rem" />
				</Box>
			</Layout>
		);
	}

	return (
		<Layout>
			<Stack
				direction="column"
				justifyContent="flex-start"
				spacing={2}
				alignItems="center"
				sx={{
					width: '100%',
					maxWidth: '400px',
					height: '100%',
					p: 2,
				}}
			>
				<DatePicker
					value={date}
					onChange={(newValue) => newValue && setDate(newValue.toISOString())}
					renderInput={(params) => <TextField {...params} fullWidth />}
					loading={isLoading}
					disabled={isLoading}
					minDate={new Date('2019-02-01')}
					orientation="portrait"
					openTo="month"
					views={['year', 'month']}
				/>

				<Stack
					width="100%"
					direction="column"
					justifyContent="flex-start"
					alignItems="stretch"
					spacing={4}
					paddingBottom={8}
				>
					<Typography
						fontSize="1.5rem"
						fontWeight="300"
						width="100%"
						textAlign="center"
					>
						Resumo de entregas
					</Typography>

					{summaryByPeriod.length === 0 ? (
						<Alert sx={{ width: '100%' }} severity="success">
							Nada para exibir neste período
						</Alert>
					) : (
						summaryByPeriod.map((item) => (
							<SummaryCard data={item} key={item.period.from} />
						))
					)}
				</Stack>
			</Stack>
		</Layout>
	);
};

export default Wallet;
