import { MyLocation as MyLocationIcon } from '@mui/icons-material';
import { Box, Button, CircularProgress, Modal, Stack } from '@mui/material';
import GoogleMapReact from 'google-map-react';
import { useSnackbar } from 'notistack';
import { useMemo, useState } from 'react';
import { useGeolocated } from 'react-geolocated';
import { useNavigate } from 'react-router-dom';
import { IPackage } from '../../../DTOs';
import Marker from './Marker';

interface IDeliveriesMapProps {
	deliveries: IPackage[];
}

interface ICoordinates {
	lat: number;
	lng: number;
}

interface IDeliveryCoordinates {
	x: number;
	y: number;
}

const MyLocation = (props: any) => <MyLocationIcon {...props} color="error" />;

const DeliveriesMap = ({ deliveries }: IDeliveriesMapProps) => {
	const [isOpen, setIsOpen] = useState(false);
	const [location, setLocation] = useState<ICoordinates | null>(null);
	const [center, setCenter] = useState<ICoordinates | null>(null);
	const [zoom, setZoom] = useState(13);

	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();

	useGeolocated({
		positionOptions: {
			enableHighAccuracy: true,
		},
		userDecisionTimeout: 5000,
		onError: () => {
			enqueueSnackbar(
				'A localização do seu dispostivo não esta ativa ou não foi permitida, tente novamente',
				{
					variant: 'error',
				}
			);
			setIsOpen(false);
		},
		onSuccess: ({ coords }) => {
			setLocation({ lat: coords.latitude, lng: coords.longitude });
			setCenter({ lat: coords.latitude, lng: coords.longitude });
		},
	});

	const pinMarkers = useMemo(() => {
		if (!deliveries) return [];

		if (deliveries.length === 0) return [];

		const validCoordinates = deliveries.filter(
			(deliverie) => !!deliverie.coordinates
		);

		const coords = validCoordinates.map((o) => {
			const { x, y } = o.coordinates as IDeliveryCoordinates;

			return {
				deliverie: o,
				lat: x,
				lng: y,
			};
		});

		return coords;
	}, [deliveries]);

	const handleNavigate = (item: IPackage) => {
		setIsOpen(false);
		navigate(`/entregas/bairro`, {
			state: {
				neighborhood: item.neighborhood,
				code: item.alphaCode,
			},
		});
	};

	return (
		<Stack width="100%">
			<Button
				fullWidth
				variant="contained"
				onClick={() => setIsOpen(true)}
				disabled={!location || !center}
			>
				Ver mapa
			</Button>
			<Modal open={isOpen} onClose={() => setIsOpen(false)}>
				<Box
					width="90%"
					height="90%"
					bgcolor="#fafafa"
					display="flex"
					flexDirection="column"
					justifyContent="center"
					alignItems="center"
					position="absolute"
					top="0px"
					bottom="0px"
					left="0px"
					right="0px"
					sx={{
						transform: 'translate(5%,5%)',
					}}
				>
					{location && center ? (
						<GoogleMapReact
							options={{
								fullscreenControl: true,
							}}
							bootstrapURLKeys={{
								key: String(process.env.REACT_APP_GOOGLE_MAPS_API_KEY),
							}}
							yesIWantToUseGoogleMapApiInternals
							center={center}
							defaultZoom={13}
							zoom={zoom}
							onZoomAnimationEnd={(v) => setZoom(v)}
						>
							<MyLocation
								label="Sua localização"
								lat={location.lat}
								lng={location.lng}
							/>
							{pinMarkers.map((m) => (
								<Marker
									key={m.deliverie.code}
									delivery={m.deliverie}
									lat={m.lat}
									lng={m.lng}
									onNavigate={handleNavigate}
								/>
							))}
						</GoogleMapReact>
					) : (
						<CircularProgress
							size="6rem"
							sx={{
								color: '#fff',
							}}
						/>
					)}
				</Box>
			</Modal>
		</Stack>
	);
};

export default DeliveriesMap;
